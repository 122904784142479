import React, { useState
  // , useContext
} from "react"
import Descricao from "../../components/Descricao"
import Header from "../../components/Header"
import Botoes from "../../components/Botoes"
import '../../styles/estilos.css'
import Footer from "../../components/Footer"
import Congratulation from "../../components/Congratulation";
// import { ContextClient } from  "../../context/ContextClientProvider";


export default function Pesquisa(props) {
  // const { cliente } = useContext(ContextClient)
  const [ cong, setCong ] = useState(false)

  const mudaCong = (flag) => {
    setCong(flag)
  }

  const bloco = [
    { valor: 0, color: "#B72027" },
    { valor: 1, color: "#D42127" },
    { valor: 2, color: "#F25222" },
    { valor: 3, color: "#F37022" },
    { valor: 4, color: "#FBA723" },
    { valor: 5, color: "#FFCA26" },
    { valor: 6, color: "#ECDB11" },
    { valor: 7, color: "#E9E63F" },
    { valor: 8, color: "#C5D92A" },
    { valor: 9, color: "#AED232" },
    { valor: 10, color: "#65B74B" }
  ]

  // useEffect(() => {
  //   console.log("Pesquisa: ", cliente);
  // }, [cliente]);
  
  // console.log("Ip do caixa passado via props: ",localStorage.getItem("ipCxa"))

  return <div className="pesquisa" >
    <Header nome={cong ? 'Obrigado pela sua participação!' : `Olá, ${props.obj[0]}`} show={true} />
    

    {cong 
      ? <>
          
          <Congratulation />
          <Footer show={false} />

        </>
      : <>

          <Descricao nome={props.obj[0]} link={props.link} /> 

          <div className="div-botoes">
            <div className="pai-botoes" >
              {bloco.map((b, index) => {
                  return <Botoes 
                    key={index} 
                    numero={b.valor} 
                    color={b.color} 
                    obj={props.obj}
                    // ipcxa={props.ip}
                    mudaCong={mudaCong}
                  />
                })
              } 
            </div>
            <span className="info-botoes">Responda com um clique</span>
          </div>

          <Footer show={true} obj={props.obj} ip={props.ip} />
        </>
    }
  </div>  
}