import axios from 'axios'

// const token = "QYweXXNZqWnEORD0ei7-Xdapb5MZv_4NGTogFdUxFSCuCfW940REZDqfnx6ruER1JenXh01nX7A47Dh-wukFCJq7MPdFWo-ZY-3YRB7ERFZAuAuBE8j8zCF-f8cp5sHZN6d9VN3WGXLkEO38zMwOYthSQb8CvaTkImn6iJb7I40CkqU5lzgmw6ssSFBYrSltOAUHjmEB3UfTHT1EZfyHl8FcSD6hc_qIzbl68WHMqnI"
// const token = localStorage.getItem('token')


// Passei por aqui
const api = axios.create({
  //baseURL: "https://apiacal.acalhomecenter.com.br:8124",
  baseURL: "https://acalsls.acalhomecenter.com.br/nps",
  // baseURL: "http://10.1.0.59:8124",
  // baseURL: "http://187.72.161.194:8124",
  headers: {
    "Content-Type": "application/json"
  },
})

export { api }



// const api2 = axios.create({
//   baseURL: "http://10.1.0.59:8124",
//   headers: {
//     "Content-Type": "application/json",
//     "Authorization": `bearer ${token}`
//   },
// })

// export  {
//   api,
//   api2
// } 
