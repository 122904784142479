import React from 'react'
import { useNavigate } from "react-router-dom"
// import Feedback from '../pages/Feedback'
import '../styles/modal.css'
import congratulation from '../assests/congratulation_4.gif'
// import { ContextClient } from '../context/ContextClientProvider'

export default function Congratulation(){ 
  // const [ flag, setFlag ] = useState(false)

  let navigate = useNavigate()
  // const { cliente } = useContext(ContextClient)
  // const [ ip, setIp ] = useState('')
  let ipls = localStorage.getItem('ipcxa')

  // useEffect(() => {
  //   console.log("Congratulation: ", cliente)
  // }, [cliente])
  



  // function timeOff () {
    // let arrIp = props.ip.split('.')
    // if (arrIp[2] == '15') {
    //   arrIp[2] = '14'
    // }

    // console.log("Ip via Context API: ", cliente.Ip)
    // console.log("Ip Adicionada por useEffect: ", ip)

    // let novoIp = arrIp.join('.')
    
    // setTimeout(()=> window.location.href = `http://${cliente.Ip}:4004`, 5000)
    // setTimeout(()=> window.location.href = `http://${ip}:4004`, 10000)
    // 120000
  // }

  // function timeOff () {
  //   setTimeout(() => {
  //     navigate('/feedback');
  //   }, 500);
  // }


  // useEffect(() => {
  //   setTimeout(() => {
  //     navigate('/feedback')
  //     // setFlag(true)
  //   }, 2700)
  // })
  // console.log("Congratulation, ip do caixa via props: ", ipls.split('.')[2])

  return (
    <div 
        // className="modal" 
        // style={{display: `${props.show}`}}
      > 
        {/* <h2 className='titulo' >Obrigado pela sua nota!</h2> */}
        <img className='congrat' alt='Congratulation' src={congratulation} />
        {
          // timeOff() ipls
          // ip.split('.')[2] == '14'
          ipls.split('.')[2] === '14' && ipls.split('.')[1] === '1'
          ? setTimeout(() => window.location.href = `http://${ipls}:4004`, 2000)
          : setTimeout(() => {
              navigate('/feedback');
            }, 2000)
          // setTimeout(()=> window.location.href = `http://${cliente.Ip}:4004`, 5000)
          // setTimeout(() => window.location.href = `http://${ip}:4004`, 5000)
        }
    </div>
  )
  
  // return (
  //   !flag
  //     ? (<div 
  //         // className="modal" 
  //         // style={{display: `${props.show}`}}
  //       > 
  //         <h2 className='titulo' >Obrigado pela sua nota!</h2>
  //         <img className='congrat' alt='Congratulation' src={congratulation} />
  //     </div>)
  //   : <Feedback />
  // )
    
}