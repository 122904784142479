import React from "react"
import '../styles/estilos.css'


export default function Header(props) {

  return <div 
    className="div-header" 
    // className={`${props.show ? "div-header" : "div-header-simple"}`}
  >
    {/* <img className="img" src={logoAcal} alt="Acal"/> */}
    <span className="cliente">{props.nome ? `${props.nome.charAt(0).toUpperCase()+props.nome.slice(1)}!` : "cliente!"}</span>
    
    {/* <span className={
      `${ props.show 
        ? "cliente"
        : "cliente-simple"
      }`
    }>
      
      { props.show 
        ? props.nome
          ? "Olá, "+props.nome.charAt(0).toUpperCase()+props.nome.slice(1)+"!"
          : "Olá, Cliente!" 
        : "" 
      } */}

    {/* </span> */}

  </div>
}