import React, { useState } from "react"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import '../../styles/feedback.css'
import { api } from "../../services/api"

export function FeedbackCD () {
  const [ text, setText ] = useState("")

  // Caso o cliente receba e nao responda o feedback: 
  function timeOff () {

    setTimeout(() => {
        window.location.href = "https://pesquisa.acalhomecenter.com.br/#/retira-cd"      
    }, 90000) // 1 munito e meio
    // 120000
  }


  async function sendData () {
    api.post('/cd/feedback', 

      JSON.stringify({
        Id:           localStorage.getItem('id-nps'),
        Feedback:     text,
      }),
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    ).then((res) => {
      console.log(res)
      console.log("Sucesso!")
    })
    .catch(err => console.log("Erro ao enviar dados para o backend! ", err ))
  }


  return <div className="div-feedback">
    <Header nome={`Deixe sua sugestão`} show={false} />

    <div className="feedback">
      <span className="text-msg">É de grande valor para nós!</span>
      <div className="div-feed">
        <textarea 
          className="text-feedback" 
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="..."
          autoFocus={true}
          maxLength={300}
          rows={5}
        >
        </textarea>
        <button className="btnEnviar" onClick={async () => {
          // console.log(ip)
          await sendData()          
          await localStorage.removeItem('id-nps')

          window.location.href = "https://pesquisa.acalhomecenter.com.br/#/retira-cd"
        
        }}
        >
          Enviar
        </button>
      </div>
    </div>
    { timeOff() }
    <Footer 
      show={false} 
      // ip={ip}
    />
  </div>
}
