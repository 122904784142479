import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import '../../styles/estilos.css'
import '../../styles/modal.css'
import congratulation_gif from '../../assests/congratulation_4.gif'
import axios from "axios"
import Descricao from '../../components/Descricao';
// import { FeedbackCD } from '../Feedback/indexCD';
import { useNavigate } from 'react-router-dom'


export default function PesquisaCD (props) {

  const [ cong, setCong ] = useState(false)
  let navigate = useNavigate('');

  const mudaCong = (flag) => {
    setCong(flag)
  }

  const bloco = [
    { valor: 0, color: "#B72027" },
    { valor: 1, color: "#D42127" },
    { valor: 2, color: "#F25222" },
    { valor: 3, color: "#F37022" },
    { valor: 4, color: "#FBA723" },
    { valor: 5, color: "#FFCA26" },
    { valor: 6, color: "#ECDB11" },
    { valor: 7, color: "#E9E63F" },
    { valor: 8, color: "#C5D92A" },
    { valor: 9, color: "#AED232" },
    { valor: 10, color: "#65B74B" }
  ]

  // setTimeout(() => {
  //   navigate('/feedback');
  // }, 2000)

  return <div className="pesquisa" >
    <Header nome={cong ? "Obrigado pela participação" : "Olá, Seja bem vindo(a)" } show={true} />


    {cong 
      ? 
        <> 
          <Congratulation  />
          { 
            setTimeout(() => {
              console.log("Apareceu!");
              // setCong(prev => prev = false)
              navigate('/feedback-cd');
            }, 2500)
          }
          <Footer show={false} />
        </>
      : <>
          <Descricao nome="Nome teste 1" /> 
          <div className="div-botoes">
            <div className="pai-botoes" >
              {bloco.map((b, index) => {
                  return <Botoes 
                    key={index} 
                    numero={b.valor} 
                    color={b.color} 
                    obj={props.obj}
                    mudaCong={mudaCong}
                  />
                })
              } 
            </div>
            <span className="info-botoes">Responda com um clique</span>
          </div>
          <Footer show={true} />
        </>
    }
  </div>  
}



// ================================== Botoes =======================================
function Botoes(props) {

  const [ nota, setNota ] = useState("");

  useEffect(() => {
    setNota(props.numero)
  }, [props.numero, nota])

  async function sendData () {

    console.log("Enviado!")

    props.mudaCong(true)
    await axios.post("https://apiacal.acalhomecenter.com.br:8124/cd/nps2",
      JSON.stringify({
        // Id: cliente.Id,
        Cgccpf:       "",
        Email:        "", 
        Telefone:     "",
        TipoPesquisa: "RETIRA - CD",
        Pedido:       "",
        Nota:         props.numero.toString() || ""
      }),
      {
        headers: {
          "Content-Type": "application/json",
        }
      }
    ).then((res) => {
      console.log('ID: ', res.data.id);
      localStorage.setItem('id-nps', res.data.id);
      console.log("Gravado!");
      return <Congratulation />
    })
    .catch(err => console.log("Erro ao enviar dados para o backend! ", err.status ))
    
  }

  return (
    <div 
      onClick={() =>  {
        sendData()
      }}
      className="botoes" 
      style={{
        backgroundColor: props.color
      }}
    >  
      <span className="numero">{props.numero}</span>
    </div>
  )
}



//================================ Congratulation =================================
function Congratulation(){ 
  return (
    <div> 
      <img className='congrat' alt='Congratulation' src={congratulation_gif} />
    </div>
  )    
}
