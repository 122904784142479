import React, { useEffect } from "react"
import {  useNavigate } from "react-router-dom"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
// import Feedback from "../Feedback"
import '../../styles/confirmacao.css'

export default function Confirmacao () {

  let navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/feedback')
    }, 2500)
  })

  return <div>
    <Header />
    <div className="div-confirmacao">
      <span className="msg">Pesquisa enviada com sucesso!</span>
        {/* <Feedback /> */}
    </div>
    <Footer />
  </div>
}