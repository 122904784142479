import React, { useContext, useState } from "react"
// import { Navigate, useNavigate } from "react-router-dom"
import '../styles/estilos.css'
// import { api2 } from "../services/apiAny"
import { ContextClient } from "../context/ContextClientProvider"
import  Congratulation from "./Congratulation"
import axios from "axios"
import { useEffect } from "react"

export default function Botoes(props) {
  const { cliente, setCliente } = useContext(ContextClient)
  const [ nota, setNota ] = useState("");
  // console.log("Nota: ", props.numero)

  useEffect(() => {
    setNota(props.numero)
  }, [props.numero, nota])

  async function sendData () {
    props.mudaCong(true)

    await axios.post("https://acalsls.acalhomecenter.com.br/nps/nps2",
    // await axios.post("http://10.1.0.59:8124/nps2",
      JSON.stringify({
        // Id: cliente.Id,
        Cgccpf:       cliente.Cgccpf.toString() || "",
        Email:        cliente.Email.toString() || "", 
        Telefone:     cliente.Telefone.toString() || "",
        TipoPesquisa: cliente.TipoPesquisa.toString() || "",
        Pedido:       cliente.Pedido.toString() || "",
        Nota:         props.numero.toString() || ""
      }),
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `bearer ${localStorage.getItem("token")}`
        }
      }
    ).then((res) => {
      
      setCliente({
        Id:           res.data.id,
        Nome:         props.obj[0].toString(),
        Cgccpf:       cliente.Cgccpf.toString() || "",
        Email:        cliente.Email.toString() || "",
        Telefone:     cliente.Telefone.toString() || "" ,
        TipoPesquisa: cliente.TipoPesquisa.toString() || "",
        Pedido:       cliente.Pedido.toString() || "",
        Nota:         props.numero.toString() || "",
      })
      // return <Congratulation show="block" />
      return <Congratulation />
    })
    .catch(err => console.log("Erro ao enviar dados para o backend! ", err.status ))


  //   await api2.post("/nps2",
  //      JSON.stringify({
  //       // id: cliente.id,
  //       Cgccpf:       cliente.Cgccpf.toString() || "",
  //       Email:        cliente.Email.toString() || "", 
  //       Telefone:     cliente.Telefone.toString() || "",
  //       TipoPesquisa: cliente.TipoPesquisa.toString() || "",
  //       Pedido:       cliente.Pedido.toString() || "",
  //       Nota:         props.numero || ""
  //     }
  //   ))
  //   .then((res) => {
  //     // console.log("Retorno antes da nota: ", res.data)
      
  //     setCliente({
  //       Id:           res.data.id,
  //       Nome:         props.obj[0].toString(),
  //       Cgccpf:       cliente.Cgccpf.toString() || "",
  //       Email:        cliente.Email.toString() || "",
  //       Telefone:     cliente.Telefone.toString() || "" ,
  //       TipoPesquisa: cliente.TipoPesquisa.toString() || "",
  //       Pedido:       cliente.Pedido.toString() || "",
  //       Nota:         props.numero
  //     })
  //     return <Congratulation show="block" />
  //   })
  //   .catch(err => console.log("Erro ao enviar dados para o backend! ", err ))
  }

  // console.log("Retorno antes da nota: ", localStorage.getItem('token'))

  return (
    <div 
      onClick={() =>  {
        sendData()
        console.log("Botoes: ", cliente)
        // return <Congratulation show="block" ip={props.ipcxa} />
      }}
      className="botoes" 
      style={{
        backgroundColor: props.color
      }}
    >  
      <span className="numero">{props.numero}</span>
    </div>
  )
}
