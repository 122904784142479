import React from "react"


export default function Bloco (props) {

  return (
    <div className="bloco">
      {/* <a href="https://www.facebook.com/acalhomecenter"> */}
      <a href={props.social}>
        <img 
          src={props.img} 
          style={{width: "40px"}} 
          alt=""
        />
      </a>
      {/* <a className="" href="#" onClick={() => {
        window.location.href = "https://www.acalhomecenter.com.br/"
        }}
      >
          <img src={facebook} style={{width: "20px"}} />
        </a> */}
    </div>
  )
}