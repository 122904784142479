import React, { useState, useContext } from "react"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import '../../styles/feedback.css'
import { ContextClient } from "../../context/ContextClientProvider"
// import axios from "axios"
import { useEffect } from "react"
import { api } from "../../services/api"

export default function Feedback () {
  const [ text, setText ] = useState("")
  const { cliente } = useContext(ContextClient)

  const [ ip, setIp ] = useState('')

  useEffect(() => {
    setIp(localStorage.getItem('ipcxa'))
  }, [])


  // Caso o cliente receba e nao responda o feedback: 
  function timeOff () {

    setTimeout(() => {
      const verifyIp = /(^1$|^3$|^5$|^7$|^9$|^11$|^17$|^19$)/gi;
      if(verifyIp.test(ip.split('.')[2]) && ip.split('.')[1] === '1') {

        window.location.href = `http://${ip}:4004`
      
      } else {
        window.location.href = "https://www.acalhomecenter.com.br/"
      }
    }, 60000)
    // 120000
  }


  async function sendData () {
    // await axios.post("https://apiacal.acalhomecenter.com.br:8124/feedback",
    api.post('/feedback', 

      JSON.stringify({
        Id:           cliente.Id,
        Cgccpf:       cliente.Cgccpf.toString() || "",
        Email:        cliente.Email.toString() || "",
        Telefone:     cliente.Telefone.toString() || "",
        TipoPesquisa: cliente.TipoPesquisa.toString() || "",
        Nota:         cliente.Nota.toString() || "",
        Feedback:     text,
        Pedido:       cliente.Pedido.toString() || "",
      }),
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `bearer ${localStorage.getItem("token")}`
        }
      }
    ).then((res) => {
      console.log(res)
      console.log("Sucesso!")
    })
    .catch(err => console.log("Erro ao enviar dados para o backend! ", err ))
  }


  return <div className="div-feedback">
    <Header nome={`${cliente.Nome}, deixe sua sugestão`} show={false} />

    <div className="feedback">
      <span className="text-msg">É de grande valor para nós!</span>
      <div className="div-feed">
        <textarea 
          className="text-feedback" 
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="..."
          autoFocus={true}
          maxLength={300}
          rows={5}
        >
        </textarea>
        <button className="btnEnviar" onClick={async () => {
          // console.log(ip)
          await sendData()          
          await localStorage.removeItem('token')

          const verifyIp = /(^1$|^3$|^5$|^7$|^9$|^11$|^17$|^19$)/gi;
          if(verifyIp.test(ip.split('.')[2]) && ip.split('.')[1] === '1') {

            window.location.href = `http://${ip}:4004`
          
          } else {
            window.location.href = "https://www.acalhomecenter.com.br/"
          }   
        }}
        >
          Enviar
        </button>
      </div>
    </div>
    { timeOff() }
    <Footer show={false} ip={ip}/>
  </div>
}
