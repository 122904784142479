import React from "react"
import BtnNotificacao from "./BtnNotificacao"
// import facebook from '../assests/facebook.svg'
import Bloco from "./Bloco"
import { useEffect } from "react"
import { useState } from "react"

export default function Footer(props) {
  const [ ipPdv, setIpPdv ] = useState("")
  const [ showFooter, setShowFooter ] = useState(true)

  
  useEffect(() => {

    setIpPdv(props.ip)
  }, [props.ip])

  useEffect(() => {

    // console.log("IP useEffect - 2: ", ipPdv)

    // let show = validaIP(ipPdv)

    setShowFooter(ipPdv === "" ? true : false) 
    // setShowFooter(show)

  }, [ipPdv])
  
  // function validaIP (ip) {

  //   console.log("Valida IP: ",ip)

  //   if (ip !== "" || ip !== undefined) {

  //     if (ip.match(/(^10)(.)(1)(.)((900)|(150)|(15)|(13)|(11)|[1|3|5|7|8|9])(.)((1([0-9]{1,2})?)|(2([0-5]{1,2})?)?)/g)) {
        
  //       console.log("IP é valido: ", ip)
  //       setShowFooter(false)
  //       return true
  //     } else {
  
  //       console.log("IP não é valido: ", ip)
  //       return false
  //     }
  //   } else {
  //     console.log("IP não é valido: ", ip)
  //     return false
  //   }
    
  // }
  
  
  // console.log("SHOWFOOTER: ", showFooter)



  return <div className="div-footer" >
    <div className="info-footer" >
      Precisa de ajuda para finalizar sua compra? Entre em contato conosco
    </div>

    <div className="fun-zap" >
      <div className="bloco-zap">
        <span className="titulo-zap">SAC</span>
        <div className="tel-zap">(85) 3492-5001</div>
        <span className="horario-zap"> 
          <span>
            Segunda a Sexta 
          </span><br />
          <span>
            Das 8h às 18h
          </span><br/>
          <span>
            Sábado Das 8h à 18h
          </span>
        </span>
      </div>

      <div className="bloco-zap">
        <span className="titulo-zap">Central de Vendas</span>
        <div className="tel-zap">(85) 3492-5001</div>
        <span className="horario-zap"> 
          <span>
            Segunda a Sexta 
          </span><br />
          <span>
            Das 8h às 18h
          </span><br/>
          <span>
            Sábado Das 8h à 18h
          </span>
        </span>
      </div>
      
      <div className="bloco-zap">
        <span className="titulo-zap">Central Corporativa</span>
        <div className="tel-zap">(85) 3492-5001</div>
        <span className="horario-zap"> 
          <span>
            Segunda a Sexta 
          </span><br />
          <span>
            Das 8h às 18h
          </span><br/>
          <span>
            Sábado Das 8h à 18h
          </span>
        </span>
      </div>
    </div>

    <div className="footer" >
      {/* <div className="f-top" style={{display: "none"}}> 
        <div className="f-top" style={{display: `${showFooter ? 'flex' : 'none'}`}} >
      */}
    
      <div className="f-top" style={{display: `${showFooter ? 'flex' : 'none'}`}} >
        <div className="titulo-canais">
          Acompanhe também pelas redes socias
        </div>
        <div className="canais" >
          <Bloco
            social="https://www.instagram.com/acalhomecenter/"
            img="https://lojaacal.vteximg.com.br/arquivos/insta-email.png"

          />

          <Bloco
            social="https://www.facebook.com/acalhomecenter"
            img="https://lojaacal.vteximg.com.br/arquivos/face-email.png"
          />

          <Bloco
            social="https://www.youtube.com/channel/UCdNtm9V4cdX1J4jMQ5DP6Tg"
            img="https://lojaacal.vteximg.com.br/arquivos/youtube-email.png"
          />
        </div>
      </div>

      <div className="f-body">
        <span>
          ACAL Home Center - CNPJ: 07.201.916/0001-59
        </span><br />

        <span>
          Rua Padre Cicero nº 400 - Bairro Rodolfo
        </span><br/>

        <span>
          Teófilo CEP 60430-585 - Fortaleza, CE
        </span><br />
         
      </div>

      <div className="f-bottom">
        {props.show 
          ? <BtnNotificacao obj={props.obj} flagNotificacao={props.flagNotificacao} />
          : ""
        }
      </div>
    </div>
  </div>
}