import React, { useEffect, useContext, useState } from "react"
import { Buffer } from 'buffer'
import { useParams } from 'react-router-dom'
import Pesquisa from "../Pesquisa"
import { api } from "../../services/api"
import { ContextClient } from "../../context/ContextClientProvider"

export default function Home() {

  const [ clicou, setClicou ] = useState(1);


  const handleClicou = () => {
    setClicou(prev => prev + 1)
    console.log(clicou);
    
    if (clicou % 2 === 0) {
      // alert('Clique para voltar ao silde.'); 
      window.location.href = `http://${localStorage.getItem("ipcxa")}:4004`;
    }

    setTimeout(() => {
      setClicou(prev => prev = 1)
    }, 2000)
  }


  const { setCliente } = useContext(ContextClient)

  let { urlHash } = useParams()
  let urlString = Buffer.from(urlHash, 'base64').toString().split('/')

  localStorage.setItem('ipcxa', urlString[6]);



  // Caso o cliente receba e nao responda a pesquisa:
  function timeOff () {
    
    setTimeout(() => {  
      if (localStorage.getItem("ipcxa") === 'undefined') {
        // console.log("Ip is underfined! IP: ", localStorage.getItem("ipcxa"));
        window.location.href = "https://www.acalhomecenter.com.br/";
        return;

      } else {

        // console.log("Ip is not underfined! IP: ", localStorage.getItem("ipcxa"));
  
        if (localStorage.getItem("ipcxa").split('.')[2] === '14' && localStorage.getItem("ipcxa").split('.')[1] === '1') {
  
          window.location.href = `http://${localStorage.getItem("ipcxa")}:4004`;
          return;
  
        } else {
  
          window.location.href = `http://${localStorage.getItem("ipcxa")}:4004`;
          return;
        }
      }
    // }, 5000)  // 10 sec
    }, 90000)  // 1.5 minutes
    // }, 1200000) // 2 minutes 
  }

  async function sendData () {
    await api.post("/nps", JSON.stringify(
      {
        Cgccpf:       urlString[1].toString(),
        Email:        urlString[2].toString(),
        Telefone:     urlString[3].toString(),
        TipoPesquisa: urlString[4].toString(),
        Pedido:       urlString[5].toString()
      }
    ))
      .then((res) => {
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('idUser', res.data.id)

        setCliente({
          Id:           res.data.id,
          Nome:         urlString[0].toString(),
          Cgccpf:       urlString[1].toString(),
          Email:        urlString[2].toString(),
          Telefone:     urlString[3].toString(),
          TipoPesquisa: urlString[4].toString(),
          Pedido:       urlString[5].toString(),
        })
      })
      .catch(err => console.log("Erro ao enviar dados para o back! ", err ))
  }

  useEffect(() => {
    sendData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div>
      {urlString
        ? (
          <>
            <div onClick={handleClicou} id="pular-pesquisa"></div>
            <Pesquisa obj={urlString} link={urlString[4].toString()} />,
            {timeOff()}
          </>
          )  
        : <h1>Nenhuma informação encontrada!</h1>
      }
  </div>
}