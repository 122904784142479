import React, { useContext, useState } from "react"
import { ContextClient} from "../context/ContextClientProvider"
// import { api2 } from "../services/apiAny"
import axios from "axios"

export default function BtnNotificacao(props) {
  // const { notificacao, setNotificacao } = useContext(ContextClient) 
  const [ notificacao, setNotificacao ] = useState(true) 
  const { cliente } = useContext(ContextClient)
  
  function alterNotificacao(){
    if (notificacao) {
      setNotificacao(false)
      sendNotificacao()

      return false
    }

    return true
  }
  
  async function sendNotificacao () {

    await axios.post("https://apiacal.acalhomecenter.com.br:8124/blacklist",
    // await axios.post("http://10.1.0.59:8124/blacklist",
      JSON.stringify({
        Cgccpf:       cliente.Cgccpf.toString(),
        Email:        cliente.Email.toString(), 
        Telefone:     cliente.Telefone.toString(),
        TipoPesquisa: cliente.TipoPesquisa.toString()
      }),
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `bearer ${localStorage.getItem("token")}`
        }
      }
    ).then((res) => console.log(res.data))
    .catch(err => console.log("Erro ao enviar dados para o backend! ", err ))

    // api2.post('/blacklist', {
    //   Cgccpf:       cliente.Cgccpf.toString(),
    //   Email:        cliente.Email.toString(), 
    //   Telefone:     cliente.Telefone.toString(),
    //   TipoPesquisa: cliente.TipoPesquisa.toString()
    // })
    // .then((res) => console.log(res.data))
    // .catch(err => console.log("Erro ao enviar dados para o backend! ", err ))
    return 
  }

  return <div className="div-notificacao">
      <span
        className="notificacao1"
      >Se não quiser receber esse tipo de conteúdo, </span>
      <span 
        onClick={(e) => {
          alterNotificacao()
        }} 
        style={{color: "#009FE3"}}
      > 
        Clique aqui!
      </span>
    </div>
}