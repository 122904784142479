import React, { createContext, useState } from "react";

export const ContextClient = createContext()

export default function ContextClientProvider ({children}) {

  const [ cliente, setCliente ] = useState()

  return <ContextClient.Provider
    value={{
      cliente, setCliente
    }}
  >
    {children}
  </ContextClient.Provider>
}