import { Routes, Route, HashRouter } from 'react-router-dom'
import './App.css';
import Home from './pages/Home';
import Feedback from './pages/Feedback';
import ContextClientProvider from './context/ContextClientProvider';
import Confirmacao from './pages/Confirmacao';
// import Teste from './pages/Teste';
import PesquisaCD from './pages/PesquisaCD';
import { FeedbackCD } from './pages/Feedback/indexCD';
// import NotFound from './pages/NotFound';
// import Started from './pages/Started';
// import bgAcal from '../src/assests/bg-acal2.jpg'

function App() {
  
  return (
      // <div className="App" style={{ backgroundImage: `url(${bgAcal})` }}  >
      <div className="App" >
        <ContextClientProvider>
          {/* <Router basename={process.env.PUBLIC_URL}> */}
          <HashRouter basename="/" >
            <Routes>
              {/* <Route path='/'element={<Started />} /> */}
              {/* <Route path='pesq' element={<Home />} > */}
              <Route path=':urlHash' element={<Home />} />
              {/* </Route> */}
              <Route path='feedback' element={<Feedback />} />


              <Route path='confirmacao' element={<Confirmacao />} />
              {/* <Route path='teste' element={<Teste />} /> */}

              <Route path='retira-cd' element={<PesquisaCD nome="seja bem vindo(a)" />} />
              <Route path='feedback-cd' element={<FeedbackCD />} />
              {/* <Route path='*' element={<NotFound />} /> */}

            </Routes>
          </HashRouter>
          {/* </ Router> */}
        </ContextClientProvider>
    </div>
  );
}

export default App;
