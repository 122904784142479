import React, { useEffect, useState } from "react";
import '../styles/estilos.css';


export default function Descricao(props) {

  const [ flag, setFlag ] = useState(false);
 
  useEffect(() => {

    if (props.link.match("LINK ENTREGA")) {
      // setLink(props.link)
      setFlag(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  return <div className="div-descricao">
    {/* Sendo usado no Header */}
    {/* <span className="cliente">Olá, {props.nome ? props.nome.charAt(0).toUpperCase()+props.nome.slice(1)+"!" : "cliente!"}</span> */}
    
    <span className="agradecimento">Obrigado(a) por escolher a Acal Home Center!</span>

    <span className="info">
      Queremos aprender, com <span className="fontb">1 pergunta</span> em <span className="fontb">30 segundos,</span> como foi a sua experiência com a nossa empresa. Agradecemos sua colaboração.
    </span>
    {flag
      ? <span className="pergunta">
          De 0 a 10 o quanto você recomendaria ou retornaria a usufruir da nossa logística?
        </span>
      : <span className="pergunta">
          Em uma escala de 0 a 10, o quanto você indicaria a Acal Home Center a um amigo ou familiar?
        </span>
    }
    {/* <span className="pergunta">Em uma escala de 0 a 10, o quanto você indicaria a Acal Home Center a um amigo ou familiar?</span> */}
  </div>
}